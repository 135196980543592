<template>
  <div>
    <div
      class="col-md-6 m-auto row"
      v-for="(item, key) in list"
      :key="key"
      @mouseover="item.isHovering = true"
      @mouseout="item.isHovering = false"
      :class="{ hovering: item.isHovering }"
    >
      <div class="col-1 mt-5 time_line_area">
        <div class="time_line" :class="{ no_line: key + 1 === list.length }">
          <span class="ball"></span>
        </div>
      </div>
      <div class="col time_line_text py-3">
        <h1 class="fw-bold">{{ item.year }}</h1>
        <p>{{ item.text }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    data: Array,
  },
  data() {
    return {
      list: JSON.parse(JSON.stringify(this.data)),
    };
  },
  mounted() {
    // let vm = this
    // let arr = []
    // this.data.forEach(function(item,key){
    //   arr.push(item)
    //   // arr[key].isHovering = false
    //   // vm.$set(vm.isHovering,key,false)
    // })
    // vm.list = arr
  },
  methods: {},
};
</script>
<style lang="scss">
.time_line {
  height: 140%;
  width: 1px;
  position: relative;
}
.ball {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: rgba(170, 170, 170, 0.329);
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, -50%);
}
.time_line_text {
  text-align: left;
  padding-left: 3rem;
}
.hovering {
  .time_line_text {
    color: #2575ed;
  }
  .time_line {
    transition: 0.5s;
    background: #2575ed;
  }
  .time_line.no_line {
    background: unset;
  }
  .ball {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    border: 2.5px solid #2575ed;
    background: #fff;
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media screen and (max-width: 989px) {
  .time_line_text {
    padding-left: 1rem;
  }
}
</style>
