<template>
  <div class="about">
    <Banner :banner="banner" />
    <section class="first_section" id="history">
      <h1 class="fw-bold">發展沿革</h1>
      <div class="line_bottom m-auto mb-5"></div>
      <div class="col-xl-9 col-11 mx-auto section">
        <div class="timeline_year"></div>
        <div class="col-12 mx-auto bg-white p-3 px-md-5">
          <TimeLine :data="time_line_data" />
        </div>
      </div>
    </section>
    <section class="col-xl-9 col-11 mx-auto first_section" id="AI">
      <h1 class="fw-bold">AI數據發展</h1>
      <div class="line_bottom m-auto mb-5"></div>
      <div class="col-10 mx-auto">
        <p>
          短影音直播是未來新媒體最大商機、每個用戶、商家、企業將透過短影音直播建立“創造、娛樂、音樂、購物、新聞、搜尋、社交、商機”八大元素關聯性、嗨吧以八元素為核心創造未來美好生活。
        </p>
        <EightElements :data="eight_elements" />
      </div>
    </section>
  </div>
</template>
<script>
// @ is an alias to /src
import Banner from "@/components/Banner";
import EightElements from "@/components/EightElements";
import TimeLine from "@/components/TimeLine";

export default {
  components: {
    Banner,
    EightElements,
    TimeLine,
  },
  data() {
    return {
      banner: {
        url: require("../assets/about/banner.jpg"),
        text: {
          title: "翰成數位科技",
          content:
            "翰成數位科技成立於2005年，隨著數位潮流演進，我們累積了豐富的國際貿易、產品代運營、網紅經紀、直播電商、短影音創作經驗。<br>2021年，我們完成了HCE直播產業基地建設，耗時三年開發的短影音推播引擎-嗨吧APP，全力發展直播短影音生態圈。<br>翰成數位期許協助每一位企業客戶、用戶做好直播影音商務推廣，全力發展直播短影音巨大商機。",
        },
      },
      info: {
        text: {
          title: "HCE娛樂",
          content: `翰成數位 HCE 直播產業基地占地 1314 坪、基地建置 10 間攝影棚、專攻直播短影音業務建構台灣直播短影音生態圈、孵化直播短影音專業人才。`,
          button_link: "https://google.com",
        },
      },
      time_line_data: [
        {
          year: "2005",
          text: "公司創立成立運動內衣品牌、3個月就轉型。",
          isHovering: false,
        },
        {
          year: "2006,2007",
          text: "發展 Youtube 短影音平台、KOL 網紅經紀。",
          isHovering: false,
        },
        {
          year: "2008",
          text: "成立有樂購物網電商平台、銷售冷凍、冷藏食品。",
          isHovering: false,
        },
        {
          year: "2010",
          text: "累積東南亞直銷通路代工四年經驗。",
          isHovering: false,
        },
        {
          year: "2016",
          text: "阿榮嚴選直播平台上線。",
          isHovering: false,
        },
        {
          year: "2017",
          text: "專攻冷凍水產、肉品、冷藏水果、區別市場。",
          isHovering: false,
        },
        {
          year: "2018",
          text: "建立供應鏈、代理生產國內外 食品、健康食品、時尚、生活 四大類產品。",
          isHovering: false,
        },
        {
          year: "2019",
          text: "開發短影音推播引擎、建立影音大數據關聯性分析。",
          isHovering: false,
        },
        {
          year: "2020",
          text: "開發專攻短影音 (嗨吧 APP) 。",
          isHovering: false,
        },
        {
          year: "2021",
          text: "建立直播產業基地、整合 (短影音 + 直播) 內容電商平台。",
          isHovering: false,
        },
      ],
      eight_elements: [
        {
          title: "軌跡數據",
          img: require("../assets/about/icon_grid_01.svg"),
        },
        {
          title: "服務數據",
          img: require("../assets/about/icon_grid_02.svg"),
        },
        {
          title: "趨勢數據",
          img: require("../assets/about/icon_grid_03.svg"),
        },
        {
          title: "關聯性探勘",
          img: require("../assets/about/icon_grid_04.svg"),
        },
        {
          title: "翰成數位<br>AI大數據",
          img: require("../assets/about/icon_grid_05.svg"),
        },
        {
          title: "關聯性分析",
          img: require("../assets/about/icon_grid_06.svg"),
        },
        {
          title: "物聯網",
          img: require("../assets/about/icon_grid_07.svg"),
        },
        {
          title: "智慧家庭",
          img: require("../assets/about/icon_grid_08.svg"),
        },
        {
          title: "電動車",
          img: require("../assets/about/icon_grid_09.svg"),
        },
      ],
    };
  },
};
</script>
<style lang="scss"></style>
